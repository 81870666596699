import React, { lazy, Suspense} from 'react';
const CycleCommunity = lazy(() => import('./components/cycle-community') );
const Footer = lazy(() => import('../../includes/Footer') );
const Partners = lazy(() => import('./components/partners') );
const FunWithoutFuel = lazy(() => import('./components/fun-without-fuel') );
const StepByStep = lazy(() => import('./components/step-by-step') );
//const DiscoverBikeRoute = lazy(() => import('./components/discover-bike-route')); 
const Platform = lazy(() => import('./components/platform') );
const Articles = lazy(() => import('./components/articles') );
const TestClient = lazy(()=> import('./components/TestClient') );
const TestClientCarousel = lazy(()=> import('./components/TestClientCarousel') );

function LateLoadedComponent() {
    return (
        <>
            <main className="site-main">
				<Suspense fallback={<div/>}>
					<FunWithoutFuel/>
				</Suspense>
				<Suspense fallback={<div/>}>
					<CycleCommunity/>
				</Suspense>
				<Suspense fallback={<div/>}>
					<StepByStep/>
				</Suspense>
				<Suspense fallback={<div/>}>
					<Platform/>
				</Suspense>
				<Suspense fallback={<div/>}>
					<Articles/>
				</Suspense>
				
				<section className="testimonial_sec animation-element">
					<Suspense fallback={<div/>}>
						<TestClientCarousel/>
					</Suspense> 
					<Suspense fallback={<div/>}>
						<TestClient/>
					</Suspense>
				</section>
				<Suspense fallback={<div/>}>
					<Partners/>
				</Suspense>
			</main>
			<Suspense fallback={<div/>}>
				<Footer/>
			</Suspense>
        </>
    )
}

export default LateLoadedComponent;
